import React from 'react';

import { CardContent, Grid, Typography } from '@material-ui/core';
import styles from './styles';

export interface RsusMetaDataProps {
  rsus: string[];
}

export const RsusMetaData = ({ rsus }: RsusMetaDataProps) => {
  const classes = styles();

  return (
    <CardContent className={classes.detailStyle}>
      <Grid container spacing={1} style={{ marginTop: 10, marginLeft: 5 }}>
        <Grid container spacing={1}>
          <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
            <strong>Rsus: {'\n'}</strong>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              <ul>
                {rsus.map((rsu) => (
                  <li key={rsu}>{rsu}</li>
                ))}
              </ul>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
};
